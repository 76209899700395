<template>
  <v-app :style="{background: background}">
    <v-app-bar
      app
    >
      <div class="d-flex align-center">
        <v-img
          alt="Beyond Labz Logo"
          class="mr-2"
          contain
          :src="require('./assets/logo.png')"
          width="180"
        />
      </div>

      <v-tabs v-if="loggedIn" class="links" :value="$route">
        <v-tab
          v-for="(item, i) in navItems"
          :key="i"
          :to="item.path"
          class="mt-4"
        >
          <v-icon size="20">{{item.icon}}</v-icon>
          <v-card-text class="text-body-1 font-weight-bold px-1 hidden-xs-only">
            {{item.label}}
          </v-card-text>
        </v-tab>
      </v-tabs>

      <v-spacer></v-spacer>

      <v-btn
        v-if="loggedIn"
        @click="logout"
        color="primary"
        outlined
      >
        <span class="mr-2">Logout</span>
        <v-icon>{{ iconLogout }}</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import { mdiAccountMultiple, mdiCurrencyUsd, mdiFaceAgent, mdiLogout } from '@mdi/js'
import { mapGetters } from 'vuex'

export default {
  name: 'App',

  components: {
  },

  data: () => ({
    navOptions: [
      {
        label: 'Admin',
        icon: mdiAccountMultiple,
        path: '/admin',
        permission: 'admin'
      },
      {
        label: 'Sales',
        icon: mdiCurrencyUsd,
        path: '/sales',
        permission: 'sales'
      },
      {
        label: 'Support',
        icon: mdiFaceAgent,
        path: '/support',
        permission: 'support'
      }
    ],
    iconLogout: mdiLogout
  }),
  computed: {
    ...mapGetters(['permissions']),
    navItems () {
      return this.navOptions.filter(item => this.permissions.includes(item.permission))
    },
    loggedIn () {
      return this.$store.getters.loggedIn
    },
    theme () {
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    },
    background () {
      return this.$vuetify.theme.themes[this.theme].background
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('logout')
      this.$router.push('/')
    }
  }
}
</script>

<style>
::selection {
  background: #a4e9f3;
}
</style>
