import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin.vue'),
    meta: {
      permission: 'admin'
    }
  },
  {
    path: '/sales',
    name: 'Sales',
    component: () => import(/* webpackChunkName: "about" */ '../views/Sales.vue'),
    meta: {
      permission: 'sales'
    }
  },
  {
    path: '/support/:mode?/:search?',
    name: 'Support',
    component: () => import(/* webpackChunkName: "about" */ '../views/Support.vue'),
    meta: {
      permission: 'support'
    }
  }
]

const router = new VueRouter({
  routes
})

function checkPermissions (meta) {
  return meta.permission ? store.getters.permissions.includes(meta.permission) : true
}

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => checkPermissions(record.meta))) {
    next()
  } else {
    next('/')
  }
})

export default router
