import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null
  },
  getters: {
    loggedIn (state) {
      return state.user !== null
    },
    permissions (state) {
      return state.user === null ? [] : state.user.permissions
    }
  },
  mutations: {
    SET_USER (state, user) {
      Vue.set(state, 'user', user)
    }
  },
  actions: {
    login (context, data) {
      fetch('/api/login', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
        .then(res => res.json())
        .then(data => {
          context.commit('SET_USER', data.user)
        })
    },
    logout (context) {
      context.commit('SET_USER', null)
    }
  }
})
